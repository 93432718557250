import React, {FC, useState, useEffect} from 'react';
import {useMutation} from '@apollo/client';

import {Form, FormItem, Input} from '../../components/form';
import {Translate} from '../../components/translations';

import Button from '../../components/button';

import {UPDATE_USER_INFORMATION} from '../../graphql/mutations';
import {UpdateUserProps, UserUpdateFormState, UserUpdateFormSecondaryState, UpdateUserMutationData} from '../../types';

const UpdateUser: FC<UpdateUserProps> = ({user}) => {
  const [updateUserMutation, {loading}] = useMutation<UpdateUserMutationData>(UPDATE_USER_INFORMATION);

  const [formData, setFormData] = useState<UserUpdateFormState>({
    firstName: '',
    lastName: '',
    phone: '',
    company: '',
  });

  useEffect(() => {
    setFormData({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phone: user.phone || '',
      company: user.company || '',
    });
  }, [user]);

  const [secondaryData, setSecondaryData] = useState<UserUpdateFormSecondaryState>({
    error: false,
    errorMessage: '',
  });

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async () => {
    setSecondaryData({...secondaryData, error: false, errorMessage: ''});
    const {data} = await updateUserMutation({
      variables: {
        ...formData,
      },
    });
    if (data) {
      const {
        UserOps: {
          updateInformation: {error, message},
        },
      } = data;
      if (error) {
        setSecondaryData({...secondaryData, error: true, errorMessage: message});
      } else {
        window.location.reload();
      }
    }
  };

  return (
    <Form className="mb_60 update-form" onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-6">
          <FormItem>
            <Translate name="FIRST_NAME">
              {text => (
                <Input
                  required
                  placeholder={text}
                  type="text"
                  name="firstName"
                  onChange={fieldChangeHandler}
                  value={formData.firstName}
                />
              )}
            </Translate>
          </FormItem>
          <FormItem>
            <Translate name="LAST_NAME">
              {text => (
                <Input
                  required
                  placeholder={text}
                  type="text"
                  name="lastName"
                  onChange={fieldChangeHandler}
                  value={formData.lastName}
                />
              )}
            </Translate>
          </FormItem>
          <FormItem>
            <Translate name="EMAIL">
              {text => (
                <Input
                  disabled
                  required
                  placeholder={text}
                  type="email"
                  name="email"
                  onChange={fieldChangeHandler}
                  value={user ? user.email : ''}
                />
              )}
            </Translate>
          </FormItem>
        </div>
        <div className="col-md-6">
          <FormItem>
            <Translate name="COMPANY">
              {text => (
                <Input
                  placeholder={text}
                  type="text"
                  name="company"
                  onChange={fieldChangeHandler}
                  value={formData.company}
                />
              )}
            </Translate>
          </FormItem>
          <FormItem>
            <Translate name="PHONE">
              {text => (
                <Input
                  type="text"
                  name="phone"
                  onChange={fieldChangeHandler}
                  value={formData.phone}
                  placeholder={text}
                />
              )}
            </Translate>
          </FormItem>
        </div>
        {secondaryData.error && (
          <div className="alert alert-danger">
            <Translate name={secondaryData.errorMessage} />
          </div>
        )}
      </div>
      <Button primary className="btn_three" loading={loading}>
        <Translate name="UPDATE" />
      </Button>
    </Form>
  );
};

export default UpdateUser;
